<template>
    <div>
        <p v-if="lableText !== ''" class="label_css h-5">{{lableText}}</p>
        <div v-click-outside-input="closeList" @click.stop.prevent="onClickInputAction" :id="sectionId"  :class="opened ? 'border border-primary ' : 'border border-gray2'" class="relative  rounded text-text1 focus:border-primary focus:ring-1">
             <div v-if="selectedArr.length > 0" class="flex flex-wrap p-1 pr-14">
                <div v-for="(cell, index) in selectedArr" :key="index" class="my-px">
                    <div class="flex text-xs mx-1 ml-0 py-1 px-2 rounded-lg border border-teal-300 bg-gray-200 text-gray-800 font-bold">
                    {{ cell[keyName] }}
                        <div class="ml-1" @click.stop.prevent="selectNewVal(cell, index, 'close')">
                        <span class="p-1 cursor-pointer"><i class="fa-solid fa-xmark"></i></span>
                        </div>
                    </div>
                </div>
             </div>
            <input
            @keypress="keyPressAction"
            @keypress.enter="enterkeyPressAction"
            @keyup="keyup" 
            @keydown="keydown"
            @blur="blurAction"
            @input="inputChangeAction(inputValue)"
            @focus="focus"
            :readonly="setReadOnly"
            v-model="inputValue"
            :placeholder="placholderText"
            type="text"
            :class="`pr-${inputValue.length > 0 ? '12' : '8'}
            flex text-text1
            `"
            :id="inputId"
            :autofocus="setAutoFocust"
            autocomplete="off"
            class="cust_text_field py-3 px-2  w-full text-text1 bg-white rounded items-center">

            <span v-if="showArrow" :class="showPlus ? 'right-10' : 'right-3'" class="place-self-center absolute top-0 h-full flex justify-center items-center">
                <p v-if="opened" class=" inline-flex mt-1"><i class="fas fa-chevron-up text-gray3 heading-6"></i></p>
                <span v-if="!opened" class="mt-2"><i class="fas fa-chevron-down text-gray3 heading-6"></i></span>
            </span>
            <span v-if="showPlus" class="place-self-center absolute top-0 right-2 border-gray2 border-l h-full flex justify-center items-center">
                <span @click="addNew" class=""><i class="pr-1 pl-2 cursor-pointer fas fa-plus text-primary heading-6" ></i></span>
            </span>

            <span v-if="showClearIcon" :class="inputValue.length > 0 ? 'visible' : 'invisible'" class="cursor-pointer place-self-center absolute mt-px top-5 right-8">
              <img @click="clearInput" height="10px" width="10px" src="@/assets/images/svg/gray-search.svg" alt="clearInput">
            </span>

            <div :id="`${this.listId}`" class=" absolute z-10 mt-px flex-col w-full max-h-64 h-auto bg-white overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg" style="overflow-x: auto;" v-show="opened" >
                <div v-if="displayList.length > 0">
                    <div v-if="showselectAll"
                         class="flex shadow items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100" >
                      
                      <span @click.stop.prevent="removeallselect()"  v-if="items.length === selectedArr.length" class="text-primary"><i class="fa-solid fa-square-check w-5 h-5"></i>
                      <span class="pl-4 heading-4 text-text1">Deselect All</span>
                      </span>
                      <p @click.stop.prevent="selectalluser()" v-else class="text-gray3"><i class="fa-regular fa-square w-5 h-5"></i>
                      <span class="pl-4 heading-4 text-text1">Select All</span>
                      </p>
                    </div>

                    <div v-for="(data, index) in displayList" :key="index"
                    :class="`${downPressCount === index ? 'bg-gray-200 border-gray-200' : ''} ${inputValue === data[keyName] ? 'bg-info border-info' : '' }`"
                    @click.stop.prevent="selectNewVal(data, index, 'select')" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100" >
                      <span v-if="data.isSelect" class="text-primary"><i class="fa-solid fa-square-check w-5 h-5"></i></span>
                      <p v-else class="text-gray3"><i class="fa-regular fa-square w-5 h-5"></i></p>
                      <div class="pl-4 whitespace-nowrap overflow-hidden heading-4">
                          {{ data[keyName] }}
                      </div>
                    </div>
                </div>
                <div v-else>
                <div v-if="inputValue !== ''" class=" heading-4 p-2">
                    No Record Found
                    </div>
                <div v-if="inputValue === '' && searchCallApi" class=" heading-4 p-2">
                    Start typing to search...
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
Vue.directive('click-outside-input', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
    props: [ "closedList", "searchCallApi", "listId", "showselectAll", "sectionId", "selectedValues", "idKey", "keyName", "items", "showPlus", "inputext", 'lableText', 'inputId', 'autoFocus', "showArrow", "placholderText", "showClearIcon", "setReadOnly", "fieldError"],
  data() {
    return {
      backSpacecount:0,
      scrollCount: null,
      downPressCount: null,
      displayList: [],
      selectedArr: [],
      showError: false,
      callSearch: false,
      setAutoFocust: false,
      opened: false,
      isSelectAll: false,
      inputValue: ''
    };
  },
  watch: {
    inputValue: {
      handler() {
        this.backSpacecount = 0
        if (this.callSearch) {
            let filter = this.inputValue.toLowerCase();
            this.displayList = []
            for (let index = 0; index < this.items.length; index++) {
                if (this.inputValue.toLowerCase().indexOf(filter) > -1 === this.items[index][this.keyName].toLowerCase().indexOf(filter) > -1) {
                 this.displayList.push(this.items[index])
                }
            }
            if (this.isSelectAll) {
              this.displayList.forEach(element => {
                element.isSelect = true
              });
            }
        }
      }
    },
     fieldError: {
        handler () {
          this.showError = this.fieldError
        }
    },
    closedList: {
        handler () {
          if (this.closedList) {
            this.opened = false
          }
        }
    },
     items: {
        handler () {
          this.displayList = []
          if (this.items.length > 0) {
            this.items.forEach(info => (
                this.displayList.push({...info, isSelect: false})
            ))
          }
           this.displayList.forEach(a => {
            const findex = this.selectedArr.findIndex(x => x[this.idKey] === a[this.idKey])
              if (findex > -1) {
                a.isSelect = true
              }  
            })
        },
        deep: true
    },
     selectedValues: {
        handler () {
          this.selectedArr = []
            this.selectedValues.forEach(element => {
              element.isSelect = true
            });
          this.selectedArr = this.selectedValues
        },
        deep: true
    },
    inputext: {
        handler () {
          this.callSearch = false
            if (this.inputext) {
                this.inputValue = this.inputext
            } else {
              this.inputValue = this.inputext
            }
        }
    }
  },
  mounted() {
    if (this.items.length > 0) {
      this.items.forEach(info => (
        info.isSelect = false
    ))}
    
    this.selectedArr = this.selectedValues
    this.selectedArr.forEach(element => {
      element.isSelect = true
    });

    this.showError = this.fieldError
     setTimeout(() => {
      if (this.inputext) {
        this.inputValue = this.inputext
      } else {
        this.inputValue = ''
      }
     }, 200);
    setTimeout(() => {
      if (this.autoFocus) {
        document.getElementById(`${this.inputId}`).focus()
        this.$emit('onFocusAction')
      }
    }, 50);
  },
  methods: {
    selectalluser () {
      this.selectedArr = []
      this.displayList = []
      this.items.map((data)=> {
        this.selectedArr.push({...data, isSelect: true})
        this.displayList.push({...data, isSelect: true})
      })
      this.isSelectAll = true
      this.$emit("selectNewForVal", this.selectedArr);
    },
    removeallselect () {
      this.selectedArr = []
      this.displayList.forEach(element => {
        element.isSelect = false
      });
      this.isSelectAll = false
      this.$emit("selectNewForVal", this.selectedArr);
    },
    checkKey (e) {
      e = e || window.event;
      if (e.keyCode === 40) {
        this.downArrowAction()
      }
      if (e.keyCode === 38) {
        this.upArrowAction()
      }
      if (e.keyCode === 9) {
        this.opened = false
      }
    },
    downArrowAction () {
      if (this.displayList.length - 1 > this.downPressCount && this.downPressCount !== null) {
        this.downPressCount = this.downPressCount + 1
        this.scrollCount = this.scrollCount + 52
        document.getElementById(`${this.listId}`).scrollTop = this.scrollCount
      }
      if (this.downPressCount === null) {
        this.downPressCount = 0
        this.scrollCount = 0
        document.getElementById(`${this.listId}`).scrollTop = this.scrollCount
      }
    },
    upArrowAction () {
      if (this.downPressCount > 0 && this.scrollCount > 0) {
        this.downPressCount = this.downPressCount - 1
      this.scrollCount = this.scrollCount - 52
      document.getElementById(`${this.listId}`).scrollTop = this.scrollCount
      }
      
    },
    inputChangeAction (text) {
      this.opened = true
      this.scrollCount = null
       this.downPressCount = null
      if (this.searchCallApi) {
        this.$emit('inputChangeAction', text)
      } else {
        this.callSearch = true
      }
    },
    keyPressAction () {
        this.$emit('keyPressAction')
        let documentvalue = document.getElementById(`${this.inputId}`)
        documentvalue.onkeydown = this.checkKey();
    },
    enterkeyPressAction () {
       this.selectNewVal(this.displayList[this.downPressCount], this.downPressCount, 'select')

    },
    selectNewVal (data, indexA, val) {
        setTimeout(() => {
          let rect = document.getElementById(`${this.sectionId}`).getBoundingClientRect()
          let inputHeight = document.getElementById(`${this.sectionId}`).offsetHeight
          if (window.innerHeight - rect.bottom < 200) {
              document.getElementById(`${this.listId}`).style.bottom = `${inputHeight}px`
          } else {
            document.getElementById(`${this.listId}`).style.bottom = "auto"
          }
        }, 500);

        if (val === "select") {
         if (this.displayList[indexA][this.idKey] === data[this.idKey] && this.displayList[indexA].isSelect === false) {
           this.displayList.forEach(a => {
             if (a[this.idKey] === data[this.idKey]) {
               a.isSelect = true
               this.selectedArr.push(a)
             }
           })
         } else {
           const findex = this.selectedArr.findIndex(x => x[this.idKey] === data[this.idKey])
           this.displayList[indexA].isSelect = false
           this.selectedArr.splice(findex, 1)
         }
      } else if (val === "close") {
          this.displayEmpSelect = false
          const findex = this.selectedArr.findIndex(x => x[this.idKey] === data[this.idKey])
          this.displayList.forEach(a => {
            if (a[this.idKey] === data[this.idKey]) {
              a.isSelect = false
            }
          })
          this.selectedArr.splice(findex, 1)
      }
       this.$emit("selectNewForVal", this.selectedArr);
       setTimeout(() => {
        document.getElementById(`${this.inputId}`).focus()
       }, 500);
    },
    keyup () {
        this.$emit('keyup')
        let documentvalue = document.getElementById(`${this.inputId}`)
        documentvalue.onkeydown = this.backspacCheck();
    },
    backspacCheck (e) {
      e = e || window.event;
      if (e.keyCode === 8) {
        if (this.inputValue === '') {
          this.backSpacecount = this.backSpacecount + 1
        }
        if (this.backSpacecount === 2 && this.selectedArr.length > 0) {
          let remvoed = this.selectedArr.pop()
          this.isSelectAll = false
          this.$emit("selectNewForVal", this.selectedArr);
          this.displayList.forEach(data => {
          if (data[this.idKey] === remvoed[this.idKey]) {
            data.isSelect = false
          }
        })
        }
        
        }
        this.backSpacecount = 1
    },
    keydown () {
        let documentvalue = document.getElementById(`${this.sectionId}`)
        documentvalue.onkeydown = this.checkKey();
    },
    blurAction () {
      this.$emit('blurAction')
        this.scrollCount = null
       this.downPressCount = null
       setTimeout(() => {
        // this.opened = false
       }, 100);
    },
    onClickInputAction () {
      let rect = document.getElementById(`${this.sectionId}`).getBoundingClientRect()
        let inputHeight = document.getElementById(`${this.sectionId}`).offsetHeight
       if (window.innerHeight - rect.bottom < 200) {
        document.getElementById(`${this.listId}`).style.bottom = `${inputHeight}px`
          // document.getElementById(`${this.listId}`).style.bottom = `52px`
       } else {
        document.getElementById(`${this.listId}`).style.bottom = "auto"
       }
        this.$emit('onClickInputAction')
        document.getElementById(`${this.inputId}`).focus()
        setTimeout(() => {
          this.opened = true
        }, 10);
    },
    clearInput () {
        this.inputValue = ''
        this.$emit('clearInputAction', '')
         document.getElementById(`${this.inputId}`).focus()
    },
    closeList () {
        this.$emit('closeList')
        this.opened = false
        this.backSpacecount = 0
    },
    addNew () {
        this.$emit('addNew')
    },
    focus () {
      if (!this.isSelectAll) {
        this.displayList = this.items
      }
      this.displayList.forEach(a => {
            const findex = this.selectedArr.findIndex(x => x[this.idKey] === a[this.idKey])
              if (findex > -1) {
                a.isSelect = true
              }  else {
                a.isSelect = false
              }
            })
      setTimeout(() => {
        this.opened = true
      }, 100);
    }
  },
};
</script>
<style scoped>
</style>
