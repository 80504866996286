<template>
  <div class="mt-4">
    <div
      class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl w-full"
    >
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">
          {{
            parseInt(this.$route.params.requestId)
              ? "Edit Request"
              : "Create Request"
          }}
        </h1>
        <div class="flex items-center gap-3"></div>
      </div>
    </div>
    <div class="flex justify-center">
      <div
        class="card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12"
      >
        <div class="grid grid-cols-2 gap-4">
          <div class="mb-4 sm:mb-0" v-if="parseInt(this.$route.params.requestId) === 0" >
            <Dropdown
              :disabled="isDisable"
              :inputext="searchForCustomer"
              :inputId="'searchreq-Customer'"
              :lableText="'Customer/Lead'"
              :placholderText="`Customer/Lead`"
              :autoFocus="true"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="true"
              :fieldError="errObj.custErr !== ''"
              :showPlus="true"
              :keyName="'companyName'"
              :listId="'customerlistid'"
              :items="customersList"
              @enterkeyPressAction="errObj.custErr = ''"
              @inputChangeAction="callsearchCust"
              @addNew="addNewCustomer"
              @selectNewForVal="selectNewCustomer"
            />
            <p v-if="errObj.custErr !== ''" class="text-error heading-7">
              {{ errObj.custErr }}
            </p>
          </div>
          <div class="mb-4 sm:mb-0">
            <TextField
              :inputId="'requtitleid'"
              :textMaxlength="100"
              :showcharLimit="true"
              :inputext="requetsObj.requestTitle"
              :placholderText="`Request Title`"
              :lableText="'Request Title'"
              :autoFocus="false"
              @inputChangeAction="(data) => (requetsObj.requestTitle = data)"
            />
          </div>
          <div v-if="requetsObj.customerId > 0 && requetsObj.fullAddress === '' && parseInt(this.$route.params.requestId) === 0"
            class="mb-4 sm:mb-0 col-span-2 text-text1"
          >
            <!-- <Dropdown 
                :inputext="jobDisplayAdd" 
                :inputId="'jobloactioninput'"
                :lableText="'Select property location'"
                :placholderText="`Select property location`"
                :autoFocus="false"
                :showPlus="true"
                :showArrow="true"
                :setReadOnly="true"
                :searchCallApi="false"
                :keyName="'fullAddress'"
                :listId="'addresslistforreq'"
                :items="addressList"
                 @addNew="addNewAddress"
                @selectNewForVal="selectReqAddress"
                :fieldError="errObj.addErr !== ''"
                @keyPressAction="errObj.addErr = ''"
                @enterkeyPressAction="errObj.addErr = ''"
                /> -->
            <p class="mb-1">
              Location
              <span
                v-if="!isOfficeAddressSel && !isAddedNewAddress"
                class="text-error text"
                >Required</span
              >
            </p>
            <div class="col-span-2">
              <div
                @click="checkboxCheck()"
                class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1"
              >
                <div class="flex items-center">
                  <div class="flex gap-1">
                    <p v-if="!isOfficeAddressSel" class="text-gray3">
                      <i class="fa-regular fa-circle h-5 w-5"></i>
                    </p>
                    <span v-else class="text-primary">
                      <i class="fa-solid fa-circle-check h-5 w-5"></i>
                    </span>
                    <span class="">At Office Location </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div
                @click="selsectFromMap"
                class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1"
              >
                <div class="flex items-center justify-between">
                  <div class="flex gap-1">
                    <p v-if="!isAddedNewAddress" class="text-gray3 mb-0">
                      <i class="fa-regular fa-circle h-5 w-5"></i>
                    </p>
                    <span v-else class="text-primary">
                      <i class="fa-solid fa-circle-check h-5 w-5"></i>
                    </span>
                    <span class="">Select From Map </span>
                  </div>
                  <div>
                    <p v-if="isAddedNewAddress">{{ otherLocationDistance }}</p>
                  </div>
                </div>
                <div v-if="isAddedNewAddress" class="">
                  {{ requetsObj.fullAddress }}
                  <div v-if="isAddedNewAddress" class="flex items-center">
                    <CheckBoxInput
                      :checkValue="requetsObj.isAddressAddToCustomer"
                      :label="``"
                      :size="'small'"
                      :disabled="false"
                      @setIsActive="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                    />
                    <span
                      class="pl-0 text-text2 cursor-pointer"
                      @click.stop.prevent="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                      >Add address in customer file</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <p v-if="errObj.addErr !== ''" class="text-error heading-7">
              {{ errObj.addErr }}
            </p>
          </div>
          <div v-else-if="requetsObj.fullAddress === '' && parseInt(this.$route.params.requestId) === 0" class="col-span-2">
            <div @click="checkboxCheck()" class="mb-2 cust_card p-2 text-text1">
              <div class="">
                <p>Location <span class="text-error text">Required</span></p>
                <p class="text-gray3 heading-7">
                  Please select Customer/Lead first
                </p>
              </div>
            </div>
          </div>
          <div v-else-if="parseInt(this.$route.params.requestId) === 0" class="col-span-2">
            <div class="mb-2 cust_card p-2 text-text1">
              <div class="flex justify-between items-center">
                <p class="text-gray3">Property Location <span @click="deleteSelectedAddress" class="text-error ml-2 heading-5 cursor-pointer">
                  <i class="fa-solid fa-trash-can"></i>
                  </span></p>
                <p @click="isSelectFromMap = true" class="text-primary heading-5 cursor-pointer">
                  <i class="fa-solid fa-pen-to-square"></i>
                </p>
              </div>
              <div class="">
                  {{ requetsObj.fullAddress }}
                  <div v-if="isAddedNewAddress" class="flex items-center">
                    <CheckBoxInput
                      :checkValue="requetsObj.isAddressAddToCustomer"
                      :label="``"
                      :size="'small'"
                      :disabled="false"
                      @setIsActive="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                    />
                    <span
                      class="pl-0 text-text2 cursor-pointer"
                      @click.stop.prevent="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                      >Add address in customer file</span
                    >
                  </div>
                </div>
            </div>
          </div>
          <div class="col-span-2 mb-4 sm:mb-0">
            <div class="heading-5" v-if="!addReqDetail">
              <span
                @click="addReqDetail = true"
                class="text-primary font-bold cursor-pointer"
                >Add details</span
              >
            </div>
            <TextAreaInput
              v-if="addReqDetail"
              :inputId="'detailsforrquest'"
              :inputext="requetsObj.serviceDetail"
              :placholderText="`Write description here...`"
              :lableText="'Details of Request'"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="1000"
              :showcharLimit="true"
              :cols="50"
              :rows="1"
              :setReadOnly="false"
              @inputChangeAction="(data) => (requetsObj.serviceDetail = data)"
            />
          </div>
          <div class="mb-4 sm:mb-0">
            <TextField
              :placholderText="`Date`"
              :setReadOnly="true"
              :autoFocus="false"
              :inputext="requetsObj.availabilityDate"
              :showDate="true"
              :lableText="'Availability Date'"
              :inputId="'availabilityid'"
              @onClickInputAction="availibilityDateShow = true"
            />
            <div v-if="availibilityDateShow" class="popup_overlay">
              <div
                style="height: 435px; width: 306px"
                class="custom_dialog rounded-xl border border-gray4 ring-gray4"
              >
                <DateTimePickerCustom
                  v-model="requetsObj.availabilityDate"
                  v-if="availibilityDateShow"
                  :showDate="true"
                  :title="'Availability Date'"
                  :showOnlyDate="true"
                  :showStartDate="true"
                  :projectSection="true"
                  @closeDateTimepickerDialog="availibilityDateShow = false"
                >
                </DateTimePickerCustom>
              </div>
            </div>
            <p v-if="errObj.dateError !== ''" class="text-error heading-7">
              {{ errObj.dateError }}
            </p>
          </div>
          <div class="mb-4 sm:mb-0">
            <TextField
              :placholderText="`Date`"
              :setReadOnly="true"
              :autoFocus="false"
              :inputext="requetsObj.otherAvailDate"
              :showDate="true"
              :lableText="'Other availability Date'"
              :inputId="'availabilityid'"
              @onClickInputAction="otherAvDateShow = true"
            />
            <div v-if="otherAvDateShow" class="popup_overlay">
              <div
                style="height: 435px; width: 306px"
                class="custom_dialog rounded-xl border border-gray4 ring-gray4"
              >
                <DateTimePickerCustom
                  v-model="requetsObj.otherAvailDate"
                  v-if="otherAvDateShow"
                  :showDate="true"
                  :title="'Other availability Date'"
                  :showOnlyDate="true"
                  :showStartDate="true"
                  :projectSection="true"
                  @closeDateTimepickerDialog="otherAvDateShow = false"
                >
                </DateTimePickerCustom>
              </div>
            </div>
            <p v-if="errObj.dateError !== ''" class="text-error heading-7">
              {{ errObj.dateError }}
            </p>
          </div>
          <div class="col-span-2">
            <p class="label_css mb-2">Preferred Time</p>
            <div class="flex flex-wrap">
              <div class="flex items-center mr-4">
                <div>
                  <CheckBoxInput
                    :checkValue="anyTime"
                    :label="``"
                    :size="'small'"
                    :disabled="false"
                    @setIsActive="anyTime = !anyTime"
                  />
                </div>
                <p
                  @click.stop.prevent="anyTime = !anyTime"
                  class="label_css cursor-pointer"
                >
                  Any Time
                </p>
              </div>
              <div class="flex items-center mr-4">
                <div>
                  <CheckBoxInput
                    :checkValue="morning"
                    :label="``"
                    :size="'small'"
                    :disabled="false"
                    @setIsActive="morning = !morning"
                  />
                </div>
                <p
                  @click.stop.prevent="morning = !morning"
                  class="label_css cursor-pointer"
                >
                  Morning
                </p>
              </div>
              <div class="flex items-center mr-4">
                <div>
                  <CheckBoxInput
                    :checkValue="afternoon"
                    :label="``"
                    :size="'small'"
                    :disabled="false"
                    @setIsActive="afternoon = !afternoon"
                  />
                </div>
                <p
                  @click.stop.prevent="afternoon = !afternoon"
                  class="label_css cursor-pointer"
                >
                  Afternoon
                </p>
              </div>
              <div class="flex items-center mr-4">
                <div>
                  <CheckBoxInput
                    :checkValue="evening"
                    :label="``"
                    :size="'small'"
                    :disabled="false"
                    @setIsActive="evening = !evening"
                  />
                </div>
                <p
                  @click.stop.prevent="evening = !evening"
                  class="label_css cursor-pointer"
                >
                  Evening
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-4 cust_card p-4 rounded-xl bg-gray1"
          v-if="parseInt(this.$route.params.requestId) === 0"
        >
          <div
            v-if="!assementObj.isSchedule"
            class="relative"
            @click="addUpdateshedule = !addUpdateshedule"
          >
            <div class="text-primary cursor-pointer">
              <span class="text-primary pr-2"
                ><i class="fa-solid fa-calendar-days heading-5"></i
              ></span>
              Add to Schedule
            </div>
          </div>
          <div v-else class="relative">
            <p class="font-bold mb-2">Assessment Details</p>
            <div v-if="assementObj.isUnschedule" class="mb-2">
              <span class="text-primary"
                ><i class="fa-solid fa-calendar-days heading-5"></i
              ></span>
              Unscheduled
            </div>
            <div v-else class="mb-2">
              <span class="text-primary mr-2"
                ><i class="fa-solid fa-calendar-days heading-5"></i
              ></span>
              <span v-if="!allDay" class="text-gray4"
                >{{ assementObj.startDateTime | logTimeShow }} -
                {{ assementObj.endDateTime | leaveTimeFilterForActivity }}</span
              >
              <span v-else class="text-gray4"
                >{{ assementObj.startDateTime | allDayTimeFill }}:
                Full-Day</span
              >
            </div>
            <div v-if="selectedTeam.length > 0">
              <span class="text-gray3">Assign to:</span>
              <span v-for="(user, index) in selectedTeam" :key="index">
                {{ user.firstName }} {{ user.lastName }}
                <span v-if="index < selectedTeam.length - 1">,</span>
              </span>
            </div>
            <span
              @click="addUpdateshedule = !addUpdateshedule"
              class="cursor-pointer absolute text-primary top-0 right-0"
              ><i class="fa-solid fa-pen-to-square"></i
            ></span>
            <span
              @click="clearAssmnt()"
              class="cursor-pointer absolute text-error top-0 right-6"
              ><i class="fa-solid fa-trash"></i
            ></span>
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <div class="text-rigth flex gap-2">
            <Button
              :btnSize="'medium'"
              :textColor="'text1'"
              :btnColor="'gray1'"
              :btnText="'Cancel'"
              @buttonAction="$router.go(-1)"
            />
            <Button
              :btnSize="'medium'"
              :textColor="'white'"
              :btnColor="'success'"
              :btnText="'Save'"
              @buttonAction="crateRequest()"
            />
          </div>
        </div>
      </div>
    </div>

    <div></div>
    <AddCustomer v-if="addCustomer" />
    <AddressAdd :showAdditional="true" v-if="addNewReqAdd" />
    <AddUpdateShedule
      :requetsObj="requetsObj"
      :requestId="requetsObj.requestId"
      :selectedTeam="selectedTeam"
      :assementObj="assementObj"
      :addUpdateshedule="true"
      v-if="addUpdateshedule"
    />
    <ReqAddressAddUpdate
      :addressList="addressList"
      :requetsObj="requetsObj"
      v-if="isSelectFromMap"
    />
  </div>
</template>
<script>
import DateTimePickerCustom from "@/View/components/dateTimepicker";
import CheckBoxInput from "@/View/components/checkBoxInput.vue";
import MyJobApp from "@/api/MyJobApp.js";
import Button from "@/View/components/globalButton.vue";
import TextAreaInput from "@/View/components/textAreaInput.vue";
// import SheduleVisit from './components/SheduleVisit.vue'
import Dropdown from "@/View/components/dropdown.vue";
import TextField from "@/View/components/textfield.vue";
import AddCustomer from "@/View/customer/components/AddCustomer.vue";
import deboucneMixin from "@/mixins/debounce.js";
import moment from "moment";
import AddressAdd from "@/View/components/AddressAdd.vue";
import AddUpdateShedule from "./AddUpdateShedule.vue";
import ReqAddressAddUpdate from "./ReqAddressAddUpdate.vue";
import timeZoneWithCountryCode from "@/assets/timeZoneWithCountryCode.json";
import milesCountry from "@/assets/milesCountry.json";

export default {
  name: "customer",
  components: {
    DateTimePickerCustom,
    CheckBoxInput,
    AddUpdateShedule,
    Button,
    TextAreaInput,
    AddCustomer,
    AddressAdd,
    TextField,
    // SheduleVisit,
    Dropdown,
    ReqAddressAddUpdate,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      otherLocationDistance: "",
      LoginUserlatLong: null,
      OfficelatLong: null,
      isAddedNewAddress: false,
      isSelectFromMap: false,
      addReqDetail: false,
      isOfficeAddressSel: false,
      addNewReqAdd: false,
      islead: false,
      allDay: false,
      isDisable: false,
      jobDisplayAdd: "",
      selectedTeam: [],
      customersList: [],
      addCustomer: false,
      showAddSelectAddr: false,
      availibilityDateShow: false,
      addUpdateshedule: false,
      otherAvDateShow: false,
      anyTime: false,
      morning: false,
      afternoon: false,
      evening: false,
      addressList: [],
      searchForCustomer: "",
      searchApitext: "",
      errObj: {
        custErr: "",
        addErr: "",
      },
      requetsObj: {
        requestId: 0,
        customerId: 0,
        requestTitle: "",
        serviceDetail: "",
        instructions: "",
        availabilityDate: "",
        otherAvailDate: "",
        arrivalTime: "",
        startTime: "",
        endTime: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        latitude: 0,
        longitude: 0,
        geofenceRangeDiameter: 0,
        fullAddress: "",
        houseNo: "",
        floorNumber: "",
        addressTitle: "",
        buildingName: "",
        assignToList: [],
        isAddressAddToCustomer: false,
        isUnschedule: false,
        isScheduled: false,
      },
      assementObj: {
        instructions: "",
        isUnschedule: true,
        isAnyTime: false,
        startDateTime: "",
        endDateTime: "",
        isSchedule: false,
      },
      getCustList: () => {},
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler());
  },
  computed: {},
  watch: {},
  mounted() {
    this.LoginUserlatLong = JSON.parse(
      localStorage.getItem("LoginUserlatLong")
    );
    this.OfficelatLong = JSON.parse(localStorage.getItem("orgInfo"));
    if (parseInt(this.$route.params.requestId) > 0) {
      this.isDisable = true;
      this.geReqDetail(parseInt(this.$route.params.requestId));
    }
    if (this.$route.query.customerId > 0) {
      this.isDisable = true;
      this.getCustLeadInfo(parseInt(this.$route.query.customerId));
    }
    console.log("route", this.$route.query);
    this.getCustList = this.debounce(function () {
      this.getCustomerList();
    }, 500);
    this.getCustomerList();
    this.$root.$on("addCustomerHandler", (response, data) => {
      if (response && this.addCustomer) {
        this.selectNewCustomer(data);
      }
      this.addCustomer = false;
    });
    this.$root.$on("ReqaddreHandler", (response, data, isNew) => {
      if (response && data !== null && this.isSelectFromMap) {
        if (isNew) {
          let count = 0
          this.addressList.forEach(e => {
            if (e.latitude === data.latitude && e.longitude === data.longitude) {
              count += 1
            }
          })
          if (count === 0) {
            this.isAddedNewAddress = true;
          }
          
        } else {
          this.isAddedNewAddress = false;
          this.requetsObj.isAddressAddToCustomer = false
        }
        this.setAddress(data);
      }
      this.isSelectFromMap = false;
    });
    this.$root.$on("closeScheduleHandler", (response, data) => {
      if (data !== null && response) {
        this.assementObj = data;
        this.selectedTeam = data.selectedTeam;
        var startTime = moment(data.startDateTime);
        var endTime = moment(data.endDateTime);
        var hoursDiff = endTime.diff(startTime, "hours");
        var minutesDiff = endTime.diff(startTime, "minutes") % 60;
        if (
          (hoursDiff === 23 && minutesDiff === 59) ||
          (hoursDiff === 24 && minutesDiff === 0)
        ) {
          this.allDay = true;
        } else {
          this.allDay = false;
        }
      }
      this.addUpdateshedule = false;
      document.body.style = "overflow: visible;";
    });
  },
  beforeDestroy() {
    this.$root.$off("ReqaddreHandler");
    this.$root.$off("closeScheduleHandler");
    this.$root.$off("addCustomerHandler");
  },
  methods: {
    deleteSelectedAddress () {
      this.errObj.addErr = "";
      this.jobDisplayAdd = "";
      this.requetsObj.fullAddress = "";
      this.requetsObj.addressLine1 = "";
      this.requetsObj.addressLine2 = "";
      this.requetsObj.city = "";
      this.requetsObj.state = "";
      this.requetsObj.zip = "";
      this.requetsObj.latitude = 0;
      this.requetsObj.longitude = 0;
      this.requetsObj.country = "";
      this.requetsObj.directionNote = "";
      this.requetsObj.houseNo = "";
      this.requetsObj.floorNumber = "";
      this.requetsObj.buildingName = "";
      this.isAddedNewAddress = false
      this.isOfficeAddressSel = false
    },
    clearAssmnt() {
      this.assementObj.instructions = "";
      this.assementObj.isUnschedule = true;
      this.assementObj.isAnyTime = false;
      this.assementObj.startDateTime = "";
      this.assementObj.endDateTime = "";
      this.assementObj.isSchedule = false;
    },
    crateRequest() {
      // this.noOfVisit = this.requetsObj.visitList.length + 1
      let isValid = true;
      if (this.requetsObj.customerId === 0) {
        this.errObj.custErr = "Please select customer";
        isValid = false;
      }
      if (!this.isOfficeAddressSel) {
        if ( this.showAddSelectAddr && parseInt(this.$route.params.requestId) === 0 ) {
          if ( this.requetsObj.latitude === 0 && this.requetsObj.longitude === 0 ) {
            this.errObj.addErr = "Please select Property Location";
            isValid = false;
          }
        }
      }
      let arrivaltime = [];

      if (this.morning) {
        arrivaltime.push("morning");
      }
      if (this.anyTime) {
        arrivaltime.push("anyTime");
      }
      if (this.afternoon) {
        arrivaltime.push("afternoon");
      }
      if (this.evening) {
        arrivaltime.push("evening");
      }

      this.requetsObj.arrivalTime = arrivaltime.toString();
      this.requetsObj.instructions = this.assementObj.instructions;
      this.requetsObj.startTime = this.assementObj.startDateTime;
      this.requetsObj.endTime = this.assementObj.endDateTime;
      if (this.requetsObj.availabilityDate !== "") {
        this.requetsObj.availabilityDate = new Date(
          this.requetsObj.availabilityDate
        ).toUTCString();
      }
      if (this.requetsObj.otherAvailDate !== "") {
        this.requetsObj.otherAvailDate = new Date(
          this.requetsObj.otherAvailDate
        ).toUTCString();
      }
      this.requetsObj.assignToList = [];
      this.selectedTeam.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
        };
        this.requetsObj.assignToList.push(obj);
      });
      if (isValid) {
        this.$store.dispatch("SetLoader", {
          status: true,
          message: "Loading...",
        });
        MyJobApp.saveRequest(
          this.requetsObj,
          (response) => {
            this.$router.go(-1);
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: response.message,
              color: "success",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    setAddress(data) {
      let temp = 0;
      let temp3 = "";
      if (this.LoginUserlatLong !== null) {
        temp = this.calcDistance(
          this.LoginUserlatLong.latitude,
          this.LoginUserlatLong.longitude,
          data.latitude,
          data.longitude
        );
        temp3 = ` from user`;
      } else {
        temp = this.calcDistance(
          this.OfficelatLong.latitude,
          this.OfficelatLong.longitude,
          data.latitude,
          data.longitude
        );
        temp3 = ` from office`;
      }
      let value = this.getDistanceUnit(temp * 1000);
      this.otherLocationDistance = value + temp3;

      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.requetsObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.requetsObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.requetsObj.city = data !== null ? data.city : "";
      this.requetsObj.state = data !== null ? data.state : "";
      this.requetsObj.zip = data !== null ? data.zip : "";
      this.requetsObj.latitude = data !== null ? data.latitude : 0;
      this.requetsObj.longitude = data !== null ? data.longitude : 0;
      this.requetsObj.country = data !== null ? data.country : "";
      this.requetsObj.geofenceRangeDiameter =
        data !== null ? data.selectedRadius : "";
      this.requetsObj.fullAddress = data !== null ? data.fullAddress : "";
      this.requetsObj.houseNo = data !== null ? data.houseNo : "";
      this.requetsObj.floorNumber = data !== null ? data.floorNumber : "";
      this.requetsObj.buildingName = data !== null ? data.buildingName : "";
      this.requetsObj.directionNote = data !== null ? data.directionNote : "";
      this.requetsObj.contactNumber = data !== null ? data.contactNumber : "";
      this.requetsObj.contactName = data !== null ? data.contactName : "";
      this.requetsObj.addressTitle = data.AddressTitle  ? data.AddressTitle : "";
    },
    getCustLeadInfo(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.searchForCustomer = response.Data.companyName;
          this.requetsObj.customerId = response.Data.customerId;
          this.showAddSelectAddr = true;
          this.getCustDetails(response.Data.customerId);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    selectReqAddress(data) {
      this.errObj.addErr = "";
      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.requetsObj.fullAddress = data !== null ? data.fullAddress : "";
      this.requetsObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.requetsObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.requetsObj.city = data !== null ? data.city : "";
      this.requetsObj.state = data !== null ? data.state : "";
      this.requetsObj.zip = data !== null ? data.zip : "";
      this.requetsObj.latitude = data !== null ? data.latitude : 0;
      this.requetsObj.longitude = data !== null ? data.longitude : 0;
      this.requetsObj.country = data !== null ? data.country : "";
      this.requetsObj.directionNote = data !== null ? data.directionNote : "";
      this.requetsObj.houseNo = data !== null ? data.houseNo : "";
      this.requetsObj.floorNumber = data !== null ? data.floorNumber : "";
      this.requetsObj.buildingName = data !== null ? data.buildingName : "";
    },
    selsectFromMap() {
      this.isSelectFromMap = true;
    },
    checkboxCheck() {
      this.isAddedNewAddress = false;
      this.isOfficeAddressSel = true;
      this.errObj.addErr = "";
      this.jobDisplayAdd = "";
      this.requetsObj.fullAddress = "";
      this.requetsObj.addressLine1 = "";
      this.requetsObj.addressLine2 = "";
      this.requetsObj.city = "";
      this.requetsObj.state = "";
      this.requetsObj.zip = "";
      this.requetsObj.latitude = 0;
      this.requetsObj.longitude = 0;
      this.requetsObj.country = "";
      this.requetsObj.directionNote = "";
      this.requetsObj.houseNo = "";
      this.requetsObj.floorNumber = "";
      this.requetsObj.buildingName = "";
    },
    selectNewCustomer(data) {
      this.errObj.addressErr = "";
      if (data !== null) {
        this.islead = data.isLead;
        this.jobDisplayAdd = "";
        this.requetsObj.addressLine1 = "";
        this.requetsObj.addressLine2 = "";
        this.requetsObj.city = "";
        this.requetsObj.state = "";
        this.requetsObj.zip = "";
        this.requetsObj.latitude = 0;
        this.requetsObj.longitude = 0;
        this.requetsObj.country = "";
        this.requetsObj.directionNote = "";
        this.requetsObj.houseNo = "";
        this.requetsObj.floorNumber = "";
        this.requetsObj.buildingName = "";
        this.errObj.custErr = "";
        this.errObj.addErr = "";
        this.searchForCustomer = data.companyName;
        this.requetsObj.customerId = data.customerId;
        this.showAddSelectAddr = true;
        this.getCustDetails(data.customerId);
      } else {
        this.searchForCustomer = "";
        this.requetsObj.customerId = 0;
      }
    },
    addNewCustomer() {
      this.addCustomer = true;
    },
    addNewAddress() {
      this.addNewReqAdd = true;
    },
    callsearchCust(text) {
      this.searchApitext = text;
      this.getCustList();
    },
    getCustomerList() {
      this.customersList = [];
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        "",
        (response) => {
          let tempList =
            response.Data.tableRow !== null ? response.Data.tableRow : [];
          tempList.forEach((e) => {
            let lead = e.isLead ? "(lead)" : "(customer)";
            e.companyName = e.companyName + " " + lead;
            this.customersList.push(e);
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false;
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158;
    },
    geReqDetail(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getRequestDetail(
        custId,
        (response) => {
          this.searchForCustomer = response.Data.companyName;
          this.requetsObj.customerId = response.Data.customerId;
          this.requetsObj.requestId = response.Data.requestId;
          this.showAddSelectAddr = true;
          this.reqDetail = response.Data;
          this.requetsObj.requestTitle = this.reqDetail.requestTitle;
          this.requetsObj.serviceDetail = this.reqDetail.serviceDetail;
          this.requetsObj.serviceDetail !== ""
            ? (this.addReqDetail = true)
            : (this.addReqDetail = false);
          this.requetsObj.availabilityDate =
            this.requetsObj.availabilityDate !== ""
              ? moment(new Date(this.reqDetail.availabilityDate)).format(
                  "YYYY-MM-DD"
                )
              : moment(new Date()).format("YYYY-MM-DD");
          this.requetsObj.otherAvailDate =
            this.requetsObj.otherAvailDate !== ""
              ? moment(new Date(this.reqDetail.availabilityDate)).format(
                  "YYYY-MM-DD"
                )
              : moment(new Date()).format("YYYY-MM-DD");
          let temp = response.Data.arrivalTime.split(",");
          this.selectedTeam = response.Data.assignToList;
          this.assementObj.instructions = response.Data.instructions;
          this.assementObj.isUnschedule = response.Data.isUnschedule;

          this.assementObj.startDateTime = response.Data.startTime;
          this.assementObj.endDateTime = response.Data.endTime;
          this.assementObj.isSchedule = true;
          temp.map((text) => {
            if (text === "morning") {
              this.morning = true;
            }
            if (text === "anyTime") {
              this.anyTime = true;
            }
            if (text === "afternoon") {
              this.afternoon = true;
            }
            if (text === "evening") {
              this.evening = true;
            }
          });

          if (response.Data.startTime !== "" && response.Data.endTime) {
            var startTime = moment(response.Data.startTime);
            var endTime = moment(response.Data.endTime);
            var hoursDiff = endTime.diff(startTime, "hours");
            var minutesDiff = endTime.diff(startTime, "minutes") % 60;
            if (
              (hoursDiff === 23 && minutesDiff === 59) ||
              (hoursDiff === 24 && minutesDiff === 0)
            ) {
              this.allDay = true;
              this.assementObj.isAnyTime = true;
            } else {
              this.allDay = false;
            }
          }
          this.getCustDetails(response.Data.customerId);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getCustDetails(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.addressList = [];
          if (response.Data.addressList !== null) {
            response.Data.addressList.forEach((e) => {
              let temp = 0;
              let temp3 = "";
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(
                  this.LoginUserlatLong.latitude,
                  this.LoginUserlatLong.longitude,
                  e.latitude,
                  e.longitude
                );
                temp3 = ` from user`;
              } else {
                temp = this.calcDistance(
                  this.OfficelatLong.latitude,
                  this.OfficelatLong.longitude,
                  e.latitude,
                  e.longitude
                );
                temp3 = ` from office`;
              }

              let value = this.getDistanceUnit(temp * 1000);

              this.addressList.push({
                ...e,
                selected: false,
                displayDistance: value + temp3,
                addressText:
                  `(${e.addressTitle === "" ? "Other" : e.addressTitle}) -` +
                  ` ${e.fullAddress}`,
              });
            });
            // this.addressList = response.Data.addressList !== null ? response.Data.addressList : [];
          }
          if (this.addressList.length === 1) {
            this.jobDisplayAdd = this.addressList[0].fullAddress;
            this.requetsObj.fullAddress = this.addressList[0].fullAddress;
            this.requetsObj.addressLine1 = this.addressList[0].addressLine1;
            this.requetsObj.addressLine2 = this.addressList[0].addressLine2;
            this.requetsObj.city = this.addressList[0].city;
            this.requetsObj.state = this.addressList[0].state;
            this.requetsObj.zip = this.addressList[0].zip;
            this.requetsObj.latitude = this.addressList[0].latitude;
            this.requetsObj.longitude = this.addressList[0].longitude;
            this.requetsObj.country = this.addressList[0].country;
            this.requetsObj.directionNote = this.addressList[0].directionNote;
            this.requetsObj.houseNo = this.addressList[0].houseNo;
            this.requetsObj.floorNumber = this.addressList[0].floorNumber;
            this.requetsObj.buildingName = this.addressList[0].buildingName;
          } else {
            this.deleteSelectedAddress()
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getDistanceUnit(distanceMeter) {
      let distance = "";
      let countryCode;
      const moment = require("moment-timezone");
      const countryTimeZone = moment.tz.guess(); // Guesses the user's time zone
      timeZoneWithCountryCode.map((itm) => {
        const ind = itm.timezones.findIndex((x) => x.name === countryTimeZone);
        if (ind >= 0) {
          countryCode = itm.code;
        }
      });
      const index = milesCountry.findIndex(
        (x) => x.countryCode === countryCode
      );
      if (index >= 0) {
        if (distanceMeter * 0.00062137 >= 1000) {
          distance = `999+ mi`;
        } else if (distanceMeter * 0.00062137 < 0.1) {
          distance = "";
        } else {
          distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
        }
      } else if (distanceMeter / 1000 >= 1000) {
        distance = `999+ km`;
      } else if (distanceMeter / 1000 < 0.1) {
        distance = "";
      } else {
        distance = `${(distanceMeter / 1000).toFixed(1)} km`;
      }
      return distance;
    },
    calcDistance(lat1, lon1, lat2, lon2) {
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRadians(lat1)) *
          Math.cos(this.toRadians(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
  },
};
</script>
<style scoped>
</style>